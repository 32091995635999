import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Switch, Route } from 'react-router-dom';

import '../Styles/_app.css';

import InviteCodeComponent from './InviteCodeComponent';
import RegForm from './RegForm';

const App = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get('code');
  const invitecode = queryParameters.get('invitecode');
  if (code || invitecode) {
    // return history.push(`/${code || invitecode}`);
    window.location.href = `//${window.location.host}/${code || invitecode}`;
  }

  return (
    <Switch>
      <Route path="/" exact component={InviteCodeComponent} />
      <Route path="/:invitecode" component={RegForm} />
    </Switch>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
