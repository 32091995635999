import { createSlice } from '@reduxjs/toolkit';
import { baseApiUrl } from '../config';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    invalidMeetingCode: false,
    atCapacity: false,
    loadingMeeting: false,
    isLicensed: null,
    meetingInfo: null,
    npiInfo: null,
    dropDownValuesLoading: false,
    dropDownValuesLoaded: false,
    dropDownValues: {},
    regSource: null
  },
  reducers: {
    setData(state, action) {
      Object.assign(state, action.payload);
    },
    setMeetingInfo(state, action) {
      state.meetingInfo = action.payload;
      state.invalidMeetingCode = false;
      state.atCapacity = false;
      state.loading = false;
    },
    setNpiInfo(state, action) {
      // console.log('setNpiInfo', action.payload);
      state.npiInfo = action.payload;
    }
  }
});

export const { setData, setMeetingInfo, setNpiInfo } = dataSlice.actions;

export const getMeetingInfo = (inviteCode) => (dispatch) => {
  dispatch(setData({ loading: true, invalidMeetingCode: false, atCapacity: false }));

  fetch(`${baseApiUrl}/api/meetings/${inviteCode}`).then((response) => {
    if (response.status === 404) {
      dispatch(setData({ invalidMeetingCode: true }));
      return;
    }
    if (response.status === 406) {
      dispatch(setData({ atCapacity: true }));
      return;
    }
    response.json().then((meetingInfo) => {
      dispatch(setMeetingInfo(meetingInfo));
    });
  });
};

export const getDropDownValues = () => (dispatch) => {
  // console.log('getDropdownValues');
  dispatch(setData({ dropDownValuesLoading: true }));
  fetch(`${baseApiUrl}/api/dropdownvalues`).then((response) => {
    response.json().then((jsonValues) => {
      dispatch(
        setData({
          dropDownValues: jsonValues,
          dropDownValuesLoaded: true,
          dropDownValuesLoading: false
        })
      );
    });
  });
};

export default dataSlice.reducer;
