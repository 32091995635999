export const getUrlParams = (name) => {
  const results = new RegExp(`${name}=([^&]*)`).exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]);
};

export const getTenantConfig = () => {
  if (process.env.REACT_APP_CLIENT) {
    return {
      tenant: process.env.REACT_APP_CLIENT,
      company: process.env.REACT_APP_MKG_COMPANY || 'hlx'
    };
  }

  const tenant = window.location.hostname.match(
    /((\w+)-?(hlxreg|meireg|scimedreg)?)\.[a-zA-Z-]+\.com/
  )[2];

  const getCompany = (hostName) => {
    if (hostName.indexOf('hlxreg') > -1) {
      return 'hlx';
    } else if (hostName.indexOf('meireg') > -1) {
      return 'mei';
    } else if (hostName.indexOf('scimedreg') > -1) {
      return 'scimed';
    }
    return 'hlx';
  };

  const company = getCompany(window.location.hostname);

  // console.log('#######');
  // console.log('tenant', tenant, 'company', company);
  // console.log('#######');

  return {
    tenant,
    company
    // company: window.location.hostname.indexOf('hlxreg') > -1 ? 'hlx' : 'mei'
  };
};

let apiUrl = '';

// export const apiUrl = 'http://localhost:8000';

apiUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8000';

if (process.env.REACT_APP_APIURL !== undefined) {
  apiUrl = process.env.REACT_APP_APIURL;
}

export const baseApiUrl = apiUrl;
