import React from 'react';
import moment from 'moment';

const FooterComponent = ({ company }) => {
  const getCompanyName = (company) => {
    switch (company) {
      case 'hlx':
        return 'HealthLogiX, LLC';
      case 'mei':
        return 'MEI';
      case 'scimed':
        return 'SciMed';
      default:
        return 'HealthLogiX, LLC';
    }
  };

  return (
    <div className="footer">
      <span>
        Copyright {moment().format('YYYY')} &copy; {getCompanyName(company)}.
      </span>
      All Rights Reserved.
    </div>
  );
};

export default FooterComponent;
