import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './Styles/_app.css';
import App from './components';
import * as serviceWorker from './serviceWorker';

import rootReducer from './redux';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://33b56d9bb59d4ec09450cc007bf98b48@o124700.ingest.sentry.io/4504295559987200',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2
  });
}

const store = configureStore({
  reducer: rootReducer
});

const theme = createMuiTheme({
  // palette: {
  //   primary: { main: '#52859c' },
  //   secondary: { main: '#c8dce3' }
  //   black: { main: '#000000' }
  // },
  overrides: {
    // MuiSelect: {
    //   select: {
    //     '&:focus': {
    //       backgroundColor: 'white'
    //     }
    //   }
    // },
    MuiInputLabel: {
      outlined: {
        '&$marginDense': {
          transform: 'translate(10px, 16px) scale(0.8)'
        }
      },
      formControl: {
        transform: 'translate(10px, 16px) scale(0.8)',
        width: '100%'
      },
      shrink: {
        transform: 'translate(10px, -4.5px) scale(0.7)',
        color: '#52859c',
        backgroundColor: 'white',
        paddingLeft: 5,
        zIndex: 100
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
