import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { getMeetingInfo } from '../../redux/dataSlice';

const MeetingInfoComponent = (props) => {
  const { invalidMeetingCode, atCapacity, meetingInfo, getMeetingInfo, tenant } = props;
  const inviteCode = props.match.params.invitecode;

  React.useEffect(() => {
    if (!meetingInfo) {
      getMeetingInfo(inviteCode);
    }
  }, [meetingInfo, inviteCode, getMeetingInfo]);

  React.useEffect(() => {
    // console.log('invalidMeetingCode: ', invalidMeetingCode);
    if (invalidMeetingCode || atCapacity) {
      props.history.push('/');
    }
  }, [invalidMeetingCode, atCapacity]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!meetingInfo) {
    return <CircularProgress />;
  }

  const { speaker, speaker2, venue } = meetingInfo;

  const getMeetingTitle = () => {
    // if (inviteCode === '7HYTPT') {
    //   // one off for this vericel meeting
    //   return 'Precision in Eschar Removal with NexoBrid: Clinical Pearls & Integration of NexoBrid at your Center/NexoBrid Wound Bed Evaluation Education';
    // }
    return meetingInfo.title;
  };

  return (
    <div className="meeting-info-component">
      <div className="title-text">{_.capitalize(tenant)}</div>
      <hr className="separator" />
      <div>
        <span className="bold">Event Information: </span>
      </div>
      <br />
      <div>
        <span className="bold">Topic: </span>
        {getMeetingTitle()}
      </div>
      <br />
      <div>
        <span className="bold">Speaker: </span>
        {speaker.firstname} {speaker.lastname}
        <br />
        {speaker.title && <span>{speaker.title}</span>}
        <br />
        {speaker.orgname && (
          <span>
            {speaker.orgname}
            <br />
          </span>
        )}
        {speaker.city} {speaker.state}
      </div>

      {speaker2 && (
        <>
          <br />
          <div>
            <span className="bold">Speaker 2: </span>
            {speaker2.firstname} {speaker2.lastname}
            <br />
            {speaker2.title && <span>{speaker2.title}</span>}
            <br />
            {speaker2.orgname && (
              <span>
                {speaker2.orgname},
                <br />
              </span>
            )}
            {speaker2.city} {speaker2.state}
          </div>
        </>
      )}
      <br />
      <div>
        <span className="bold">When: </span>
        {moment(meetingInfo.startdatetime).format('LLLL')} -{' '}
        {moment(meetingInfo.enddatetime).format('LT')} {meetingInfo.timezone}
      </div>
      <br />
      <div>
        <span className="bold">Location: </span>
        {venue.name}
        {venue.name.trim().toLowerCase() !== 'virtual' && (
          <>
            <br />
            {venue.address1}
            <br />
            {venue.address2 && <p>{venue.address2}</p>}
            <br />
            {venue.city && (
              <span>
                {venue.city.trim()}, {venue.state.trim()} {venue.postalcode.trim()}
              </span>
            )}
            <br />
            {venue.phone && venue.phone}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { meetingInfo, invalidMeetingCode, atCapacity } = data;

  return { meetingInfo, invalidMeetingCode, atCapacity };
};

export default withRouter(connect(mapStateToProps, { getMeetingInfo })(MeetingInfoComponent));
