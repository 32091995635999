import React from 'react';

import GenericNpiLookupComponent from './GenericNpiLookupComponent';
import HarmonyNpiLookupComponent from './HarmonyNpiLookupComponent';
import MelintaNpiLookupComponent from './MelintaNpiLookupComponent';
import VericelNpiLookupComponent from './VericelNpiLookupComponent';

const npiLookupMapping = {
  harmony: HarmonyNpiLookupComponent,
  melinta: MelintaNpiLookupComponent,
  vericel: VericelNpiLookupComponent
};

const renderNpiComponent = (tenant) => {
  // console.log('renderNpiComponent tenant', tenant);
  const NpiLookupComponent = npiLookupMapping[tenant]
    ? npiLookupMapping[tenant]
    : GenericNpiLookupComponent;
  return <NpiLookupComponent />;
};

export default renderNpiComponent;
