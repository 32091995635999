import React from 'react';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import { getMeetingInfo, setData } from '../redux/dataSlice';
import { getUrlParams, getTenantConfig } from '../config';

import HeaderComponent from './RegForm/HeaderComponent';

const { company } = getTenantConfig();

const InviteCodeComponent = (props) => {
  const [inviteCode, setInviteCode] = React.useState('');

  if (props.meetingInfo) {
    props.history.push(`/${props.meetingInfo.invitationcode}${props.history.location.search}`);
  }

  const regSource = getUrlParams('source');
  if (regSource && !props.regSource) {
    props.setData({ regSource });
  }

  return (
    <div className="invite-code-component">
      <HeaderComponent company={company} />
      <div className="invitation-code-body">
        <div>
          <p>Enter Your Invitation Code</p>
        </div>
        <div className="invite-code-box">
          <TextField
            value={inviteCode}
            onChange={(event) => {
              setInviteCode(event.target.value);
            }}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{
              style: { padding: '1px 12px' }
            }}
          />
          <Button
            variant="contained"
            onClick={() => props.getMeetingInfo(inviteCode)}
            disabled={props.loadingMeeting}
          >
            {props.loadingMeeting ? 'Loading...' : 'Register'}
          </Button>
        </div>
        {props.invalidMeetingCode && <p>The provided code is invalid.</p>}
        {props.atCapacity && (
          <p>
            Please note that registration for this meeting has reached capacity. <br />
            At this time we are no longer able to accept any additional registrations for this
            program. <br />
            Please reach out to your local sales representative if you have any questions. <br />
            Thank you.
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { invalidMeetingCode, atCapacity, meetingInfo, loadingMeeting, regSource } = data;
  return { invalidMeetingCode, atCapacity, meetingInfo, loadingMeeting, regSource };
};

export default connect(mapStateToProps, { getMeetingInfo, setData })(InviteCodeComponent);
