import React from 'react';

import Select from 'react-select';

const reactSelectStyles = (hasError) => ({
  // placeholder styles
  placeholder: (provided) => ({
    ...provided,
    color: hasError ? 'red' : 'rgba(0, 0, 0, 0.5)'
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: 14,
    borderColor: hasError ? 'red' : 'rgba(0, 0, 0, 0.3)'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2147483647
  })
});

const SelectMultiField = ({ values, options, label, onChange, error }) => {
  return (
    <>
      {values && values.length > 0 && (
        <p
          style={{
            color: '#52859c',
            fontSize: 11,
            backgroundColor: 'white',
            maxWidth: 60,
            position: 'relative',
            top: 6,
            left: 10,
            zIndex: 100,
            marginTop: -10
          }}
        >
          {label}
        </p>
      )}
      <Select
        isMulti
        isSearchable
        placeholder={label}
        options={options}
        onChange={onChange}
        value={values || []}
        styles={reactSelectStyles(error)}
      />
    </>
  );
};

export default SelectMultiField;
