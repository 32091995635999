import React from 'react';

const hlxLogo = require('../../assets/logo-hlx.png');
const meiLogo = require('../../assets/logo-mei.png');
const scimedLogo = require('../../assets/logo-scimed.png');

const HeaderComponent = ({ company }) => {
  // console.log('HeaderComponent: company = ', company);

  const getLogo = (company) => {
    switch (company) {
      case 'hlx':
        return hlxLogo;
      case 'mei':
        return meiLogo;
      case 'scimed':
        return scimedLogo;
      default:
        return hlxLogo;
    }
  };

  return (
    <div className="header">
      <img className="header-logo" src={getLogo(company)} alt="Registration" />
      <div className="header-title">Event Registration</div>
    </div>
  );
};

export default HeaderComponent;
