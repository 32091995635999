import React from 'react';
import { connect } from 'react-redux';

import MeetingInfoComponent from './MeetingInfoComponent';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import { getTenantConfig, getUrlParams } from '../../config';

import renderRegComponent from './RegFormComponent';
import renderNpiComponent from './NpiLookup';

import { getMeetingInfo, getDropDownValues, setData } from '../../redux/dataSlice';

const { tenant, company } = getTenantConfig();

const RegForm = (props) => {
  const { dropDownValuesLoading, dropDownValuesLoaded, getDropDownValues, setData } = props;

  const regSource = getUrlParams('source');
  if (regSource && !props.regSource) {
    props.setData({ regSource });
  }

  React.useEffect(() => {
    if (!dropDownValuesLoading && !dropDownValuesLoaded) {
      getDropDownValues();
    }
  }, [dropDownValuesLoading, dropDownValuesLoaded, getDropDownValues, setData]);

  const renderComponent = () => {
    if (!props.npiInfo) {
      return renderNpiComponent(tenant);
    }
    return renderRegComponent(tenant);
  };

  return (
    <div className="registration-form-component">
      <HeaderComponent company={company} />
      <div className="registration-form-body">
        <div className="left-component">{renderComponent()}</div>
        <div className="right-component">
          <MeetingInfoComponent tenant={tenant} />
        </div>
      </div>
      <FooterComponent company={company} />
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { meetingInfo, npiInfo, dropDownValuesLoading, dropDownValuesLoaded } = data;
  return { meetingInfo, npiInfo, dropDownValuesLoading, dropDownValuesLoaded };
};

export default connect(mapStateToProps, {
  getMeetingInfo,
  getDropDownValues,
  setData
})(RegForm);
