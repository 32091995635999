import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { TextField, Button, InputLabel, FormControl, Select, Grid } from '@material-ui/core';
import { setNpiInfo, setData } from '../../../redux/dataSlice';
import { baseApiUrl } from '../../../config';

const NpiLookupComponent = (props) => {
  const [knowsNPI, setKnowsNPI] = React.useState(null);
  const [lookupFields, setLookupFields] = React.useState({});
  const [isLicensedSearch, setIsLicensedSearch] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [medProHcps, setmedProHcps] = React.useState(null);

  React.useEffect(() => {
    if (
      _.isBoolean(isLicensedSearch) &&
      (!isLicensedSearch || (isLicensedSearch && _.isBoolean(knowsNPI)))
    ) {
      const lookupFields = document.getElementById('lookup-fields');
      // eslint-disable-next-line no-unused-expressions
      lookupFields?.scrollIntoView();
    }
  }, [knowsNPI, isLicensedSearch]);

  const updateLookupFields = (field) => {
    return (event) => {
      setLookupFields({ ...lookupFields, [field]: event.target.value });
    };
  };

  const { isPortrait } = useMobileOrientation();

  const isMobilePortrait = React.useMemo(() => {
    return isMobile && isPortrait;
  }, [isPortrait]);

  // React.useEffect(() => {
  //   console.log('lookupFields', lookupFields);
  // }, [lookupFields]);

  const validateNpi = (value) => {
    return /^\d+$/.test(value) && value.length === 10;
  };

  if (props.isLicensed === null) {
    return (
      <div
        style={{
          marginTop: 50,
          marginLeft: !isMobilePortrait && 50
        }}
      >
        <p>Are you a Licensed Healthcare Provider (prescriber with NPI number)?</p>
        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            className="hlx-button"
            onClick={() => {
              props.setData({ isLicensed: true });
              setIsLicensedSearch(true);
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            className="hlx-button"
            onClick={() => {
              props.setData({ isLicensed: false });
              setIsLicensedSearch(false);
            }}
          >
            No
          </Button>
        </div>
      </div>
    );
  }

  const lookupHcp = async () => {
    const isValid = validateFields();
    const baseUrl = `${baseApiUrl}/api/medprolookup?`;

    // City is not factored into the medpro search
    let params = `${Object.keys(_.pickBy({ ...lookupFields, city: null }, _.identity))
      .map((key) => key + '=' + lookupFields[key].trim())
      .join('&')}&isLicensedSearch=${isLicensedSearch}`;

    // debugger;

    if (isValid) {
      setFormErrors({});
      setLoading(true);
      const req = fetch(`${baseUrl}${params}`);
      req.then((response) => {
        response.json().then((json) => {
          setmedProHcps(json.results);
          setLoading(false);
          const hcpListElem = document.getElementById('npi-lookup-results');
          // eslint-disable-next-line no-unused-expressions
          hcpListElem?.scrollIntoView();
        });
      });
      req.catch((err) => {
        console.log('err on getting api is', err);
        setLoading(false);
      });
    }
  };

  const validateFields = () => {
    const errors = {};
    if (knowsNPI && !validateNpi(lookupFields.number)) {
      errors.number = true;
    } else if (!knowsNPI) {
      ['first_name', 'last_name', 'state'].forEach((field) => {
        if (!lookupFields[field]) {
          errors[field] = true;
        }
      });
    }
    setFormErrors(errors);
    return _.isEmpty(errors);
  };

  const renderNextButton = () => {
    return (
      <Button
        variant="contained"
        className="hlx-button"
        style={{ width: 250 }}
        onClick={() => {
          props.setData({ isLicensed: props.isLicensed, npiInfo: {} });
        }}
      >
        Continue to Registration Form
      </Button>
    );
  };

  const renderAddress = (hcp) => {
    return (
      <>
        {hcp.orgaddress1}
        {hcp.orgaddress1 && <br />}
        {`${hcp.orgcity} ${hcp.orgstate}`}
      </>
    );
  };

  const renderHcpGrid = () => {
    return (
      <Grid container spacing={3} justify={'space-between'}>
        {medProHcps.map((hcp, hcpIdx) => {
          return (
            <Grid item container xs={12} key={hcpIdx}>
              <Grid item xs={3} className="flex vertical-center">
                <Button
                  className="hlx-button select-hcp-button"
                  variant="contained"
                  onClick={() => props.setNpiInfo(hcp)}
                >
                  Select
                </Button>
              </Grid>
              <Grid item xs={4} sm={3} className="flex vertical-center">
                {hcp.npinumber}
                {hcp.npinumber && <br />}
                {hcp.statelicensenum && (
                  <>
                    {hcp.statelicensenum} {hcp.licensedstate && `(${hcp.licensedstate})`} <br />
                  </>
                )}
                {`${hcp.firstname} ${hcp.middleinitial || ''} ${hcp.lastname}`}
                {isMobilePortrait && (
                  <>
                    <br />
                    {renderAddress(hcp)}
                  </>
                )}
              </Grid>
              {!isMobilePortrait && (
                <Grid item xs={3} className="flex vertical-center">
                  {renderAddress(hcp)}
                </Grid>
              )}

              <Grid item xs={5} sm={3} className="flex vertical-center" style={{ paddingLeft: 20 }}>
                {hcp.orgstate}
                {hcp.orgstate && <br />}
                {hcp.specialty}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const rendermedProHcps = () => {
    if (medProHcps && !medProHcps.length) {
      return (
        <div>
          <div>
            None. If you are a licensed healthcare professional with no search results, please
            contact a Melinta representative for assistance. Otherwise, please continue on to
            complete the registration form.
          </div>
          <br />
          <br />
          {renderNextButton()}
        </div>
      );
    }
    if (_.isArray(medProHcps)) {
      return (
        <div>
          <div style={{ marginBottom: 10 }}>
            Click Select next to the record that lists your information:
          </div>
          {renderHcpGrid()}
        </div>
      );
    }
  };

  const renderSearchButton = () => {
    if (loading) {
      return <p>loading...</p>;
    }
    return (
      <Button
        variant="contained"
        className="hlx-button"
        style={{ cursor: 'pointer' }}
        onClick={lookupHcp}
      >
        Search
      </Button>
    );
  };

  const renderNpiLookupFields = () => {
    if (knowsNPI !== null || !isLicensedSearch) {
      if (knowsNPI) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
            <TextField
              fullWidth
              style={{ marginBottom: 20, marginTop: 0 }}
              value={lookupFields.number || ''}
              onChange={updateLookupFields('number')}
              label={'*NPI'}
              error={formErrors.number}
              margin={'dense'}
              variant={'outlined'}
              type="number"
              helperText={formErrors['number'] && 'Invalid NPI'}
            />
            {renderSearchButton()}
          </div>
        );
      }

      return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
          <TextField
            fullWidth
            style={{ marginBottom: 20, marginTop: 0 }}
            value={lookupFields.first_name || ''}
            onChange={updateLookupFields('first_name')}
            error={formErrors.first_name}
            label={'*First Name'}
            margin={'dense'}
            variant={'outlined'}
          />
          <TextField
            fullWidth
            style={{ marginBottom: 20, marginTop: 0 }}
            value={lookupFields.last_name || ''}
            onChange={updateLookupFields('last_name')}
            error={formErrors.last_name}
            label={'*Last Name'}
            margin={'dense'}
            variant={'outlined'}
          />
          <div style={{ display: 'flex', marginBottom: 20 }}>
            <div style={{ paddingRight: 5, flex: 3 }}>
              <TextField
                fullWidth
                value={lookupFields.city || ''}
                onChange={updateLookupFields('city')}
                label={'City'}
                margin={'dense'}
                style={{ margin: 0 }}
                variant={'outlined'}
                inputProps={{
                  autoComplete: 'off'
                }}
              />
            </div>
            <div style={{ paddingLeft: 5, flex: '1 100px' }}>
              <FormControl fullWidth>
                <InputLabel error={formErrors.state} htmlFor="state">
                  *State in Which You Are Licensed
                </InputLabel>
                <Select
                  native
                  variant={'outlined'}
                  value={lookupFields.state}
                  onChange={updateLookupFields('state')}
                  error={formErrors.state}
                  margin={'dense'}
                  style={{ margin: 0 }}
                  label="*State in Which You Are Licensed"
                  inputProps={{
                    name: 'state',
                    id: 'state'
                  }}
                >
                  {_.map(_.map(props.dropDownValues.states), (state) => {
                    return (
                      <option key={state.abrv} value={state.abrv}>
                        {state.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          {renderSearchButton()}
        </div>
      );
    }
  };

  const resetState = (knowsNpi, cancelButton = false) => {
    setKnowsNPI(knowsNpi);
    setLookupFields({});
    setFormErrors({});
    setmedProHcps(null);
    setLoading(false);
    if (cancelButton) {
      props.setData({ isLicensed: null });
    }
  };

  return (
    <div className="npi-lookup-component">
      <div className="npi-lookup-btns">
        {props.isLicensed && (
          <Button variant="contained" className="hlx-button" onClick={() => resetState(true)}>
            I know my NPI Number
          </Button>
        )}
        <Button variant="contained" className="hlx-button" onClick={() => resetState(false)}>
          {props.isLicensed ? 'Look up my NPI Number' : 'Look up my information'}
        </Button>
        <Button
          className="cancel-btn hlx-button"
          variant="contained"
          onClick={() => resetState(null, true)}
        >
          Cancel
        </Button>
      </div>
      <div className="npi-lookup-fields" id="lookup-fields">
        {renderNpiLookupFields()}
      </div>
      <div id="npi-lookup-results">{rendermedProHcps()}</div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { meetingInfo, isLicensed, dropDownValues } = data;
  return { meetingInfo, isLicensed, dropDownValues };
};

export default connect(mapStateToProps, { setNpiInfo, setData })(NpiLookupComponent);
